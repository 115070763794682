import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileItem, GoogleFile } from '../models/DataType';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';
import { map, mergeMap, timeout, delay } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Observable }  from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GdriveService {
  sectionBooking: string = "FolderDendaBooking";
  sectionLeadtime: string = "FolderDendaLeadtime";
  sectionBongkaranKemasan: string = "FolderBongkaranKemasan";
  sectionDenahPintuTrexmon: string = "FolderDenahPintuTrexmon";

  constructor(private global:GlobalService,private auth:AuthService) { }

  post(url: string, body: any) {
      return this.global.readConfig()
        .pipe(
          mergeMap(() => {
            var headers = new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('auth.token')}`
            });

            return this.global.http.post(this.global.config["googleApi"] + url, body, { headers: headers, withCredentials: true });
          })
        )
        .pipe(
          map(result => {
            if (result) {
              return result;
            } else {
              this.global.showErrorDialog("Something Wrong!");
            }
          })
        )
        .pipe(
          catchError(
              (error: any, caught: Observable<any>) => {
                if (error.status === 401) {
                    this.global.showErrorDialog("Silahkan login terlebih dahulu!");
                }
                throw error;
              }
          ),
        );
  }
}
