import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { GlobalService } from '../../../services/global.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  private apiUrl:string    = 'WSP/plant';

  constructor(private http:HttpClient, private global: GlobalService, private auth: AuthService) { 
    this.global.readConfig()
      .subscribe(() => {
        this.apiUrl = this.global.config["default_schema"] + "/plant";
      });    
  }

  getOrderByQueid(param:any):Rx.Observable<any> {
    let subject:Rx.Subject<any> = new Rx.Subject<any>();
    this.global.postSAP(this.apiUrl+ "/get-order-by-queid",param)
      .subscribe(rows => {
        subject.next(rows);
      }, err => {
        subject.error(err);
    });
    return subject.asObservable();
  }

  getSOByNoordLion(param:any):Rx.Observable<any> {
    let subject:Rx.Subject<any> = new Rx.Subject<any>();
    this.global.postSAP(this.apiUrl+ "/get-so-by-order-lion",param)
      .subscribe(rows => {
        subject.next(rows);
      }, err => {
        subject.error(err);
    });
    return subject.asObservable();
  }

  saveSlipsheet(param:any):Rx.Observable<any> {
    let subject:Rx.Subject<any> = new Rx.Subject<any>();
    this.global.postSAP(this.apiUrl+ "/w-slipsheet-transaction",param)
      .subscribe(rows => {
        subject.next(rows);
      }, err => {
        subject.error(err);
    });
    return subject.asObservable();
  }

  saveSlipsheetLION(param:any):Rx.Observable<any> {
    let subject:Rx.Subject<any> = new Rx.Subject<any>();
    this.global.postSAP(this.apiUrl+ "/save-slipsheet-lion",param)
      .subscribe(rows => {
        subject.next(rows);
      }, err => {
        subject.error(err);
    });
    return subject.asObservable();
  }
}